/**
 * ih.facebooklogin
 *
 * Stores functions for working with facebook login authentication
 *
 * Depends:
 *   jQuery
 *   ih
 *   FB
 *
 * @format
 */

(function ($, ih, FB) {
    "use strict";

    //    console.log("authenticatedUser", ih.authenticatedUser);
    ih = ih || {};
    ih.facebooklogin = ih.facebooklogin || {};

    ih.facebooklogin.rerequestNeeded = false;
    ih.facebooklogin.callback = false;
    ih.facebooklogin.callbackData = false;

    ih.facebooklogin.login = function () {
        FB.login(ih.facebooklogin.loginStatusCallback, {
            scope: "email,public_profile",
            return_scopes: true,
        });
    };

    ih.facebooklogin.rerequest = function () {
        FB.login(ih.facebooklogin.loginStatusCallback, {
            scope: "email,public_profile",
            return_scopes: true,
            auth_type: "rerequest",
        });
    };

    ih.facebooklogin.loginStatusCallback = function (response) {
        if (response.status == "connected") {
            if (response.authResponse.grantedScopes.includes("email")) {
                $.post(
                    ih.url("/WebForm/validate_facebook/"),
                    { facebook_data: response },
                    function (response) {
                        ih.facebooklogin.handleLoginSuccess(response.user);
                    },
                    "json"
                );
            } else {
                ih.facebooklogin.rerequest();
            }
        } else {
            ih.facebooklogin.handleLoginError(response.status);
        }
    };

    ih.facebooklogin.handleLoginSuccess = function (user) {
        //Handle User Response here
        //        console.log("HANDLE SUCCESS", user );

        ih.facebooklogin.rerequestNeeded = false;

        ih.facebooklogin.callback(user, ih.facebooklogin.callbackData);
    };
    // eslint-disable-next-line no-unused-vars
    ih.facebooklogin.handleLoginError = function (error_type) {
        //Handle Error here
        //        console.log("HANDLE ERROR", error_type);
        //Error types: 'email_needed','unknown', 'not_authorized', undefined

        ih.error(
            "We're sorry, there was a problem receiving permission from Facebook. Please try again, and be sure to grant access to your email address. Thank you!"
        );

        ih.facebooklogin.rerequestNeeded = true;
    };

    // this is done elsewhere wih the appId and version...
    // window.fbAsyncInit = function() {
    //     FB.init({
    //         appId : '{your_app_id}',
    //         cookie : true,
    //         xfbml : true,
    //         version : {version} });
    //     FB.AppEvents.logPageView();
    // };

    // (function(d, s, id) {
    //     var js, fjs = d.getElementsByTagName(s)[0];
    //     if (d.getElementById(id)) return;
    //     js = d.createElement(s); js.id = id;
    //     js.src = "//connect.facebook.net/en_US/sdk.js";
    //     fjs.parentNode.insertBefore(js, fjs);
    // } (document, 'script', 'facebook-jssdk'));

})(window.jQuery, window.ih, window.FB);
