/**
 *
 *  Carousel
 *
 *  Depends:
 *      jquery.widget.js
 *      expected markup structure
 *
 *  Usage:
 *
 *  $(".some-carousel-selector").carousel();
 *
 *  Expected Markup Structure:
 *
 *  <div class="ih-carousel">
 *      <div class="ih-carousel-arrow ih-carousel-arrow-previous disabled"></div>
 *      <div class="ih-carousel-arrow ih-carousel-arrow-next disabled"></div>
 *      <div class="ih-carousel-tray">
 *          <div class="ih-carousel-slider">
 *              <div class="ih-carousel-item">
 *              </div>
 *          </div>
 *      </div>
 *  </div>
 *
 * @format
 */

(function ($) {
    $.widget("ih.carousel", {
        // default options
        options: {
            debug: false,
            autoplay: false,
            delay: 5, // when autoplaying, the delay in seconds before moving to the next pane
            duration: 0.4, // duration in seconds for the move animation

            // callbacks
            // eslint-disable-next-line
            create: function (e, obj) {
                return false;
            },
            // eslint-disable-next-line
            change: function (e, obj) {
                return false;
            },
            // eslint-disable-next-line
            destroy: function (e, obj) {
                return false;
            },
        },

        _create: function () {
            if (this.options.debug) console.log("create carousel!");

            // DOM reference properties
            this.element.addClass("ih-carousel"); // add the class if it's not there already
            this.tray = this.element.children(".ih-carousel-tray");
            this.slider = this.tray.children(".ih-carousel-slider");
            this.items = this.slider.children(".ih-carousel-item"); // TODO: change this and make it generic .item or better yet .ih-carousel-item
            this.previousBtn = this.element.children(
                ".ih-carousel-arrow-previous"
            );
            this.nextBtn = this.element.children(".ih-carousel-arrow-next");

            // other properties
            this.totalItems = this.items.length;
            this.itemWidth = this.items.outerWidth(true); // includes padding, border, and margin
            this.itemMargin = this.itemWidth - this.items.outerWidth();

            // calculate how many items per pane
            this.itemsPerPane = Math.floor(
                (this.tray.outerWidth(true) + this.itemMargin) / this.itemWidth
            );
            this.currentItem = 1;
            this.lastItem = this.totalItems - this.itemsPerPane + 1; // this is the last item we can go to and still have a full pane of items
            this.timer = {}; // stores the timeout for autoplay

            // attach events
            this._on(this.previousBtn, {
                click: function (e) {
                    e.preventDefault();
                    this.previous(this.itemsPerPane);
                },
                "mousedown selectstart": function (e) {
                    // stop text from being selected when multiple clicking
                    e.preventDefault();
                },
            });
            this._on(this.nextBtn, {
                click: function (e) {
                    e.preventDefault();
                    this.next(this.itemsPerPane);
                },
                mousedown: function (e) {
                    // stop text from being selected when multiple clicking
                    e.preventDefault();
                },
                // eslint-disable-next-line
                selectstart: function (e) {
                    // stop text from being selected when multiple clicking
                    return false;
                },
            });

            // on window resize, recheck measurements
            this._on(window, {
                resize: function () {
                    this.resizePanes();
                },
            });

            // set the width of the slider dynamically to contain all it's children
            this.slider.css("width", this.totalItems * this.itemWidth);

            // run an initial update on the button state based on number of items
            this._updateButtons();

            // start the autoplay countdown
            if (this.options.autoplay) {
                this._countdown();
            }

            // debug values
            if (this.options.debug) console.log("totalItems:", this.totalItems);
            if (this.options.debug) console.log("itemWidth:", this.itemWidth);
            if (this.options.debug) console.log("itemMargin:", this.itemMargin);
            if (this.options.debug)
                console.log("itemsPerPane:", this.itemsPerPane);
            if (this.options.debug)
                console.log("sliderWidth:", this.slider.outerWidth());
            if (this.options.debug) console.log("lastItem:", this.lastItem);

            // fire callback
            this._trigger("create", null, this);
        },

        _destroy: function () {
            if (this.options.debug) console.log("destroy carousel");

            // events are automatically unbound via the widget class because we used this._on() to attach them

            // reset the UI if needed...

            // callback
            this._trigger("destroy", null, this);
        },

        // called when window resizes - recalculates itemsPerPane
        resizePanes: function () {
            if (this.options.debug) console.log("resizePanes()");

            this.itemWidth = this.items.outerWidth(true);
            this.itemMargin = this.itemWidth - this.items.outerWidth();

            var itemsPerPane = Math.floor(
                (this.tray.outerWidth(true) + this.itemMargin) / this.itemWidth
            );

            // if items per pane is changed, recalculate the other stuff too
            if (itemsPerPane != this.itemsPerPane) {
                this.itemsPerPane = itemsPerPane;
                this.lastItem = this.totalItems - this.itemsPerPane + 1;
            }
        },

        // go back by n items
        previous: function (n) {
            if (this.options.debug) console.log("previous()", n);
            var currentItem = this.currentItem;
            currentItem = currentItem - n;
            this.goTo(currentItem);
        },

        // go forward by n items
        next: function (n) {
            if (this.options.debug) console.log("next()", n);
            var currentItem = this.currentItem;
            currentItem = currentItem + n;
            this.goTo(currentItem);
        },

        // pane number param should be an integer
        goTo: function (itemNumber, noAnimation) {
            var c = this;
            if (this.options.autoplay) clearTimeout(this.timer);
            if (this.options.debug) console.log("goTo:", itemNumber);

            var offset = itemNumber - c.currentItem;

            //            if (this.options.debug) console.log("offset", offset);
            //            if (this.options.debug) console.log("lastItem", this.lastItem);

            // Special case: if current item is the last item
            // and we're advancing by 1, go back to the beginning.
            if (this.currentItem == this.lastItem && offset == 1) {
                itemNumber = 1;
            }
            // normal case
            else {
                var notEnoughItems = this.totalItems <= this.itemsPerPane;

                // ensure that item number falls within acceptable range
                if (
                    itemNumber == undefined ||
                    itemNumber < 1 ||
                    notEnoughItems
                ) {
                    itemNumber = 1;
                } else if (itemNumber >= this.lastItem) {
                    itemNumber = this.lastItem;
                }
            }

            this.currentItem = itemNumber;
            if (this.options.debug)
                console.log("current item", this.currentItem);

            this._updateButtons();

            // move the slider
            var left = "-" + this.itemWidth * (this.currentItem - 1) + "px";

            if (noAnimation) {
                this.slider.css("left", left);
                this._countdown();
            }
            // animate the move
            else {
                this.slider
                    .stop(true)
                    .animate(
                        { left: left },
                        c.options.duration * 1000,
                        c._countdown()
                    );
            }

            this._trigger("change", null, this);
        },

        // start a countdown to the next item
        _countdown: function () {
            var c = this;
            if (this.options.autoplay && this.totalItems > this.itemsPerPane) {
                if (this.options.debug) console.log("countdown...");
                this.timer = setTimeout(function () {
                    c.next(1);
                }, this.options.delay * 1000);
            }
        },

        // toggles the disabled class of the next and previous buttons for ui styling
        _updateButtons: function () {
            var notEnoughItems = this.totalItems <= this.itemsPerPane;
            if (this.currentItem == 1 || notEnoughItems) {
                this.previousBtn.addClass("disabled");
            } else if (this.currentItem >= 2) {
                this.previousBtn.removeClass("disabled");
            }

            if (this.currentItem == this.lastItem || notEnoughItems) {
                this.nextBtn.addClass("disabled");
            } else if (this.currentItem <= this.lastItem - 1) {
                this.nextBtn.removeClass("disabled");
            }
        },
    });
})(window.jQuery);
