/** @format */

(function ($, ih) {
    "use strict";

    // Legacy templates only
    if (!ih.data.template.isReact) {
        // this doesn't really belong here...
        $("#loginForm, #registerForm, #sendPasswordForm")
            .find("input[type=text]")
            .first()
            .focus();

        // but we'll use this file for some other misc setup work...

        // convert all widget selects to our special sauce version

        // the .standalone-banner-search-overlay is only used by L015.
        // TODO: rework it or deprecate the 2 templates that use it: Country Cabin and Earthly Interior
        $(
            ".widget select, .standalone-banner-search-overlay select"
        ).selectbox();

        // open "frame on site" target links in iframe lightboxes
        $("body").on("click", 'a[target="lightbox_iframe"]', function (e) {
            // only attempt to use modal frame if protocol matches -- otherwise, modern browsers will
            // generally refuse to load the URL in the iframe
            if (this.protocol == document.location.protocol) {
                e.preventDefault();
                ih.modalFrame(this.href);
            }
        });

        // listen for any tracking events and pass them on
        $(document).on("trackingEvent", function (e, data) {
            // console.log("trackingEvent", data);

            // If google analytics is attached to the account, there will be a gtag object.
            // UPDATE: there will always be a gtag object now because we have our own on all customer sites

            if (window.gtag) {
                var eventName = data.category + " " + data.action;
                window.gtag("event", eventName, {
                    event_category: data.category,
                    event_action: data.action,
                    event_label: data.label,
                });
            }

            // If Facebook pixel is attached, there will be a fbq object.
            // Only certain standard events are reported
            // https://www.facebook.com/business/help/952192354843755
            // Note that we don't differentiate between "Lead" and "Complete Registration".
            // We just always report "Lead"
            if (window.fbq) {
                if (data.category == "lead" && data.action == "capture") {
                    window.fbq("track", "Lead");
                }
            }
        });

        // If there are any tracking events, fire them on page load.
        $(window).load(function () {
            // console.log("page loaded", ih.data.trackingEvents);

            if (ih.data.trackingEvents) {
                $.each(ih.data.trackingEvents, function () {
                    $(document).trigger("trackingEvent", this);
                });
            }
        });
    }
})(window.jQuery, window.ih);
