/**
 * ih.googlelogin
 *
 * Stores functions for working with google login authentication
 *
 * Depends:
 *  jQuery
 *  ih
 *
 * NOTE: This file is not included directly, it is concatenated into layout_deferred.js during build time
 *
 * @format
 */
(function ($, ih) {
    "use strict";

    // handle the response from google
    const handleCredentialResponse = (response) => {
        var credential = response.credential;
        var decodedResponse = decodeJwtResponse(credential);
        var profileObject = decodedResponse.profileObj;

        // put the values we got in the response into the form, and submit it
        var $form = ih.form.regWithGoogle.form;

        $form.find("[name='social_login_id']").val(profileObject.googleId);
        $form.find("[name='name-']").val(profileObject.name);
        $form.find("[name='name']").val(profileObject.name);
        $form.find("[name='email-']").val(profileObject.email);
        $form.find("[name='email']").val(profileObject.email);
        $form.find("[name='social_login_data']").val(JSON.stringify(profileObject));

        $form.find("button.simple-form-submit").trigger('click');
    };

    // decoding a google JWT object - simple, no validation
    const decodeJwtResponse = (credential) => {
        var simpleDecode = jwt_decode(credential);

        // grab appropriate fields from the response credential
        var profileObj = {
            name: simpleDecode["name"],
            email: simpleDecode["email"],
            googleId: simpleDecode["sub"],
            emailVerified: simpleDecode["email_verified"],
            //picture: simpleDecode['picture'] ? simpleDecode['picture'] : NULL // user pic, might be useful?
        };

        return ( {profileObj} );
    };

    // This async block waits for the google.accounts window to be ready before it initializes the actual
    //   google stuff.
    (async () => {
        while (!window.google || !window.google.accounts) {
            await new Promise((resolve) => setTimeout(resolve, 10));
        }

        // setting up da google...
        console.log("initialize google with ", clientId);
        window.google.accounts.id.initialize({
            client_id: clientId,
            callback: handleCredentialResponse,
        });

        // also display the One Tap dialog
        // window.google.accounts.id.prompt();
    })();

    // do I need this stuff anymore?
    ih = ih || {};
    ih.googlelogin = ih.googlelogin || {};
    ih.googlelogin.rerequestNeeded = false;
    ih.googlelogin.callback = false;
    ih.googlelogin.callbackData = false;

    ih.googlelogin.login = function () {
        document.getElementById("googleSigninDiv").click(); // Click on the checkbox
    };

    ih.googlelogin.handleLoginSuccess = function (user) {
        ih.googlelogin.rerequestNeeded = false;

        ih.googlelogin.callback(user, ih.googlelogin.callbackData);
    };

    // eslint-disable-next-line no-unused-vars
    ih.googlelogin.handleLoginError = function (error_type) {
        ih.error(
            "We're sorry, there was a problem receiving permission from Google. Please try again, and be sure to grant access to your email address. Thank you!"
        );
        ih.googlelogin.rerequestNeeded = true;
    };

})(window.jQuery, window.ih);
