/**
 * Carousel Widgets
 * initialize any carousel widgets after the DOM loads
 *
 * @format
 */

(function ($) {
    "use strict";

    // initialize carousel widgets
    $(".featured_listings_carousel .container").each(function () {
        // get init options out of the custom data attributes on the elements
        var options = {
            autoplay: $(this).data("autoplay") == "Y",
            delay: $(this).data("delay"),
        };
        $(this).carousel(options);
    });
})(window.jQuery);
