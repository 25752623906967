/**
 *
 * Blog Widgets
 * initialize any blog widgets after the DOM loads
 *
 * @format
 */

(function ($) {
    "use strict";

    // Ummm, what is this?!!
    // the blog "more" links should go to the blog detail page, not toggle a summary vs full post in place...

    $(".blog_frame .post_body").on("click", ".blog_more", function () {
        $(this).parents(".post_body").find(".post_full").show();
        $(this).parents(".post_body").find(".post_snippet").hide();
    });

    $(".blog_frame .post_body").on("click", ".blog_less", function () {
        $(this).parents(".post_body").find(".post_snippet").show();
        $(this).parents(".post_body").find(".post_full").hide();
    });
})(window.jQuery);
